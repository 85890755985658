
function ErrorMessage () {
     return (
       
       ` <img style= "display: block; width: 250px; width: 100%; max-width: 200px;
                    height: 250px; object-fit: contain; margin: 0 auto"
            src='/static/js/modules/errorMessage/error.gif' alt="Error"/>`
    )
}

export default ErrorMessage;
